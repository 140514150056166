! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);

jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".w-nav-button");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("opened");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("opened");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-wrapper").fitVids({ ignore: "nofit" });
	}

	// Add dropdown class to main nav items
	$(".dropdown-wrapper:has('.dropdown-list')").addClass("has-dropdown");

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .main-nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".dropdown-wrapper").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".dropdown-wrapper").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	// Back to top button behaviour
	$(document).scroll(function() {
		var y = $(this).scrollTop();
		if (y > 800) {
			$('.back-to-top').css('opacity','1');
		}
		else {
			$('.back-to-top').css('opacity','0');
		}
	});

	/**
	* ----------------------------------------------------------------------
	* Webflow: Interactions: Init
	*/
	Webflow.require('ix').init([
		{slug: "dropdown-hover",name: "Dropdown Hover",value: {style: {},triggers: [{type: "hover",selector: ".dropdown-list",descend: true,stepsA: [{wait: "100ms"},{display: "block",opacity: 1,height: "auto",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}],stepsB: [{wait: "100ms"},{display: "none",opacity: 0,height: "0px",transition: "opacity 300ms ease 0ms, height 300ms ease 0ms"}]}]}},
		{slug: "flyout-hover",name: "Flyout Hover",value: {style: {},triggers: [{type: "hover",selector: ".nav-flyout-right",stepsA: [{display: "block",height: "auto",transition: "height 300ms ease 0"}],stepsB: [{display: "none",height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "flyout-hover-2",name: "Flyout Hover 2",value: {style: {},triggers: [{type: "hover",stepsA: [{display: "block"}],stepsB: [{display: "none"}]}]}},
		{slug: "cta-animation",name: "CTA Animation",value: {style: {},triggers: [{type: "hover",selector: ".cta-content",descend: true,preserve3d: true,stepsA: [{opacity: 1,height: "auto",transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: [{opacity: 0,height: "0px",transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms, height 500ms ease 0ms",x: "0px",y: "20px",z: "0px"}]},{type: "hover",selector: ".cb-heading",descend: true,preserve3d: true,stepsA: [{opacity: 1,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: [{opacity: 0.8,transition: "transform 500ms ease 0ms, opacity 500ms ease 0ms",x: "0px",y: "33px",z: "0px"}]}]}},
		{slug: "left-nav-arrow-animation",name: "Left Nav Arrow Animation",value: {style: {},triggers: [{type: "hover",stepsA: [],stepsB: []}]}},
		{slug: "row-1-cb-image-animation",name: "Row 1 CB Image Animation",value: {style: {},triggers: [{type: "hover",descend: true,stepsA: [{opacity: 0.8,transition: "transform 200ms ease-in-out 0, opacity 200 ease 0",scaleX: 1.05,scaleY: 1.05,scaleZ: 1}],stepsB: [{opacity: 1,transition: "transform 300ms ease-in-out 0, opacity 200 ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}]}]}},
		{slug: "mobile-search",name: "Mobile Search",value: {style: {},triggers: [{type: "click",selector: ".mobile-search-wrapper",stepsA: [{display: "block",opacity: 1,height: "auto",transition: "opacity 200 ease 0, height 200 ease 0"}],stepsB: []},{type: "click",selector: ".mobile-search-close",stepsA: [{display: "block",opacity: 1,transition: "opacity 200 ease 0"}],stepsB: []},{type: "click",stepsA: [{display: "none",opacity: 0,transition: "opacity 200 ease 0"}],stepsB: []}]}},
		{slug: "mobile-close-open",name: "Mobile Close Open",value: {style: {},triggers: [{type: "click",stepsA: [{display: "none",opacity: 0,transition: "opacity 200 ease 0"}],stepsB: []},{type: "click",selector: ".mobile-search-dd-button",stepsA: [{display: "block",opacity: 1,transition: "opacity 200 ease 0"}],stepsB: []},{type: "click",selector: ".mobile-search-wrapper",stepsA: [{display: "none"}],stepsB: []}]}},
		{slug: "row-1",name: "Row 1",value: {style: {},triggers: [{type: "hover",selector: ".overlay-1-image-1",stepsA: [{opacity: 0,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]},{type: "hover",selector: ".overlay-2-image-1",stepsA: [{opacity: 0.7500000000000004,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]}]}},
		{slug: "row-2",name: "Row 2",value: {style: {},triggers: [{type: "hover",selector: ".overlay-1-image-2",stepsA: [{opacity: 0,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]},{type: "hover",selector: ".overlay-2-image-2",stepsA: [{opacity: 0.7500000000000004,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]}]}},
		{slug: "row-3",name: "Row 3",value: {style: {},triggers: [{type: "hover",selector: ".overlay-1-image-3",stepsA: [{opacity: 0,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]},{type: "hover",selector: ".overlay-2-image-3",stepsA: [{opacity: 0.7500000000000004,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]}]}},
		{slug: "row-4",name: "Row 4",value: {style: {},triggers: [{type: "hover",selector: ".overlay-1-image-4",stepsA: [{opacity: 0,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]},{type: "hover",selector: ".overlay-2-image-4",stepsA: [{opacity: 0.7500000000000004,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]}]}},
		{slug: "row-5",name: "Row 5",value: {style: {},triggers: [{type: "hover",selector: ".overlay-1-image-5",stepsA: [{opacity: 0,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]},{type: "hover",selector: ".overlay-2-image-5",stepsA: [{opacity: 0.7500000000000004,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]}]}},
		{slug: "row-6",name: "Row 6",value: {style: {},triggers: [{type: "hover",selector: ".overlay-1-image-6",stepsA: [{opacity: 0,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]},{type: "hover",selector: ".overlay-2-image-6",stepsA: [{opacity: 0.7500000000000004,transition: "opacity 300ms ease 0"}],stepsB: [{opacity: 1,transition: "opacity 300ms ease 0"}]}]}},
		{slug: "row-1-cb-header-reveal",name: "Row 1 CB Header Reveal",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",offsetBot: "5%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-1-cb-reveal-1",name: "Row 1 CB Reveal 1",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",offsetBot: "5%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-1-cb-reveal-2",name: "Row 1 CB Reveal 2",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",offsetBot: "5%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-1-cb-reveal-3",name: "Row 1 CB Reveal 3",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "5%",stepsA: [{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-1-cb-reveal-4",name: "Row 1 CB Reveal 4",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "5%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-1-cb-reveal-5",name: "Row 1 CB Reveal 5",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "5%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-1-cb-reveal-6",name: "Row 1 CB Reveal 6",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "5%",stepsA: [{wait: "750ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-2-cb-reveal-1",name: "Row 2 CB Reveal 1",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "10%",stepsA: [{wait: "125ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-2-cb-reveal-2",name: "Row 2 CB Reveal 2",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "20%",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-2-cb-reveal-3",name: "Row 2 CB Reveal 3",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",offsetBot: "20%",stepsA: [{wait: "500ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-3-cb-reveal",name: "Row 3 CB Reveal",value: {style: {opacity: 0,x: "-100px",y: "0px",z: "0px"},triggers: [{type: "scroll",offsetBot: "35%",stepsA: [{opacity: 1,transition: "transform 500ms ease-out-quad 0, opacity 600ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "row-3-cb-reveal-2",name: "Row 3 CB Reveal 2",value: {style: {opacity: 0,x: "100px",y: "0px",z: "0px"},triggers: [{type: "scroll",offsetBot: "20%",stepsA: [{opacity: 1,transition: "transform 500ms ease-out-quad 0, opacity 700ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		// {"slug":"backtotop-reveal","name":"BacktoTop Reveal","value":{"style":{},"triggers":[{"type":"scroll","selector":".back-to-top","offsetTop":"50%","offsetBot":"100%","stepsA":[{"opacity":1,"transition":"opacity 200 ease 0"}],"stepsB":[{"opacity":0,"transition":"opacity 200 ease 0"}]}]}},
		{slug: "testimonial-reveal",name: "Testimonial Reveal",value: {style: {opacity: 0,scaleX: 0.7000000000000004,scaleY: 0.7000000000000004,scaleZ: 1},triggers: [{type: "scroll",offsetBot: "20%",stepsA: [{opacity: 1,transition: "transform 800ms ease-out-quad 0, opacity 500ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "testimonial-credits-reveal",name: "Testimonial Credits Reveal",value: {style: {opacity: 0,scaleX: 0.7000000000000004,scaleY: 0.7000000000000004,scaleZ: 1},triggers: [{type: "scroll",offsetBot: "20%",stepsA: [{wait: "150ms"},{opacity: 1,transition: "transform 800ms ease-out-quad 0, opacity 500ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "header-content-load",name: "Header Content Load",value: {style: {opacity: 0,x: "0px",y: "-150px",z: "0px"},triggers: [{type: "load",stepsA: [{wait: "150ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "header-content-load-2",name: "Header Content Load 2",value: {style: {opacity: 0,x: "0px",y: "-150px",z: "0px"},triggers: [{type: "load",stepsA: [{wait: "250ms"},{opacity: 1,transition: "transform 500ms ease 0, opacity 500ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}}
	]);
});

$(window).scroll(function() {
	if ($(this).scrollTop() > 200) {
		$('header').addClass("flexy-header");
	}
	else {
		$('header').removeClass("flexy-header");
	};
});
